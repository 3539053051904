import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/Section13.css';
import '../styles/Section14.css';
import thumb1 from '../assets/Captura de tela 2024-10-17 193233.png'
import thumb2 from '../assets/Captura de tela 2024-10-17 193308.png'
import thumb3 from '../assets/Captura de tela 2024-10-17 193352.png'
import depoimento1 from '../assets/WhatsApp Image 2024-10-12 at 12.08.56.jpeg'
import depoimento2 from '../assets/WhatsApp Image 2024-10-12 at 12.08.57.jpeg'
import depoimento3 from '../assets/WhatsApp Image 2024-10-14 at 21.13.13.jpeg'
import depoimento4 from '../assets/WhatsApp Image 2024-10-17 at 16.45.24.jpeg'

const Section13 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const featuredTestimonials = [
    { src: "https://www.youtube.com/embed/DEW8jTUuvuk?si=3G5BKa8Z79ZTmc9b", title: 'Raiana', thumbnail: thumb1 },
    { src: "https://www.youtube.com/embed/JmG1rjehJy4?si=OuRwbJ9Q5wDm7pE3", title: 'Letícia', thumbnail: thumb2 },
    { src: "https://www.youtube.com/embed/_JQOLt2mk0U?si=xX4Ym9tM5U-zk_Sj", title: 'Andressa', thumbnail: thumb3 },
  ];

  const carouselImages = [
    { src: depoimento1, alt: 'Depoimento 1' },
    { src: depoimento2, alt: 'Depoimento 2' },
    { src: depoimento3, alt: 'Depoimento 3' },
    { src: depoimento4, alt: 'Depoimento 4' },
  ];

  return (
    <section className="s13-section s13-dark-background" ref={sectionRef}>
      {isVisible && (
        <div className="s13-container">
          <motion.h2 
            className="s13-section-title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Histórias de Transformação
          </motion.h2>
          
          <div className="s13-featured-videos">
            {featuredTestimonials.map((testimonial, index) => (
              <motion.div 
                key={index}
                className="s13-video-testimonial"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="s13-video-wrapper">
                  <iframe
                    src={testimonial.src}
                    title={testimonial.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="s13-video"
                  ></iframe>
                </div>
                <h3 className="s13-video-title">{testimonial.title}</h3>
              </motion.div>
            ))}
          </div>

          <motion.div
            className="s13-carousel-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="s13-carousel-title">Mais Histórias Inspiradoras</h3>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={20}
              slidesPerView={1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'disabled_swiper_button'
              }}
              pagination={{ clickable: true }}
              autoplay={{ delay: 5000 }}
              loop={true}
              className="s13-swiper"
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              {carouselImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <motion.div 
                    className="s13-carousel-item"
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <img src={image.src} alt={image.alt} className="s13-carousel-image" />
                  </motion.div>
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        </div>
      )}
      <div className="s14-container">
        <motion.div 
          className="s14-quote-box"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="s14-decorative-line s14-line-left"></div>
          <div className="s14-decorative-line s14-line-right"></div>
          <motion.div 
            className="s14-quote-icon s14-quote-open"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            "
          </motion.div>
          <motion.h2 
            className="s14-quote-text"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.6 }}
          >
            Muitas mulheres já decidiram reacender a luz do amor próprio, se você está aqui
            <span className="desktop-break"></span> então chegou a sua vez.
          </motion.h2>
          <motion.div 
            className="s14-quote-icon s14-quote-close"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
          >
            "
          </motion.div>
        </motion.div>
        <motion.div 
          className="s14-decorative-element s14-element-left"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.9, duration: 0.6 }}
        >
          ✦
        </motion.div>
        <motion.div 
          className="s14-decorative-element s14-element-right"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.9, duration: 0.6 }}
        >
          ✦
        </motion.div>
      </div>
    </section>
  );
};

export default Section13;