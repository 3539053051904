import React from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../styles/Section9.css';

const PillarCard = ({ number, title, description }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div 
      ref={ref}
      className="s9-pillar-card"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.8 }}
    >
      <div className="s9-pillar-number">{number}</div>
      <h3 className="s9-pillar-title">{title}</h3>
      <p className="s9-pillar-description">{description}</p>
    </motion.div>
  );
};

const Section9 = () => {
  const { scrollYProgress } = useViewportScroll();
  const y = useTransform(scrollYProgress, [0, 1], [0, 300]);

  const pillars = [
    {
      number: "01",
      title: "AUTOCONHECIMENTO",
      description: "Apesar de ser uma busca incansável, o autoconhecimento nunca foi um quesito na educação. Não fomos estimulados a isso como realmente deveríamos ser."
    },
    {
      number: "02",
      title: "AUTOCOMPAIXÃO",
      description: "Muito mais do que se amar e se aceitar, um dos maiores desafios da autoestima é aprender a lidar com os próprios erros de maneira funcional."
    },
    {
      number: "03",
      title: "FOCO",
      description: "Por várias razões você pode se sentir insuficiente: se comparando, se diminuindo, se inferiorizando. Aqui falamos da importância de ajustar o foco das lentes as quais você se enxerga."
    },
    {
      number: "04",
      title: "AUTOVALOR",
      description: "Tudo começa dentro de nós. Somos como geradores de energia, e tudo o que você gera dentro de você é o que vai receber de volta."
    },
    {
      number: "05",
      title: "AUTOIMAGEM",
      description: "Aqui é onde muitas mulheres confundem Autoestima com Autoimagem. Você pode ter uma ótima relação com o espelho, mas em contrapartida, não ter uma boa autoestima."
    },
    {
      number: "06",
      title: "REALIZAÇÃO",
      description: "A realização é a busca de todo ser humano. E é nesse último pilar que você aprende a priorizar tudo aquilo que é importante pra você, como seus sonhos e objetivos."
    }
  ];

  return (
    <motion.section 
      className="s9-section"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="s9-animated-bg"></div>
      <motion.div className="s9-parallax-overlay" ></motion.div>
      <div className="s9-container">
        <motion.h2 
          className="s9-main-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Os 6 Pilares da Autoestima
        </motion.h2>
        <div className="s9-pillars-grid">
          {pillars.map((pillar, index) => (
            <PillarCard key={index} {...pillar} />
          ))}
        </div>
        <motion.div 
          className="s9-conclusion"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <h3>TRANSFORME SUA VIDA ATRAVÉS DESTES 6 PILARES</h3>
          <motion.p 
  className='s9-highlight-box'
  initial={{ scale: 0.95, opacity: 0 }}
  animate={{ scale: 1, opacity: 1 }}
  transition={{ duration: 0.8, delay: 0.6 }}
>
  Cada pilar é explorado através de <span className="s9-keyword">exercícios práticos,</span>{' '}
  <span className="s9-keyword">dinâmicas,</span>{' '}e{' '}
  <span className="s9-keyword">reprogramações emocionais</span>{' '}
  que guiarão você à uma profunda transformação pessoal e de autoconhecimento.
          <br/>
          <br/>
+ BÔNUS <span className='s9-keyword'>30 DOSES DE AUTOESTIMA</span> <br/> UMA PARA CADA DIA DO MÊS
</motion.p>
          <p>"você já se deixou para trás muitas vezes, chegou a hora de se dar uma chance, de
          escolher se amar."</p>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Section9;