import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../styles/Section5.css';

const Section5 = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [activeQuestion, setActiveQuestion] = useState(null);

    const questions = [
        "Quando você se olha no espelho, você se reconhece ou apenas se vê?",
        "De zero a dez que nota você dá para seu amor próprio?",
        "Quais são os seus maiores medos?",
        "Numa escala de prioridades, qual lugar você ocupa na própria vida?",
        "Você está realizando seus sonhos e metas pessoais?"
    ];

    return (
        <section className="s5-section" ref={ref}>
            <div className="s5-background"></div>
            <motion.div 
                className="s5-container"
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 1 }}
            >
                <h2 className="s5-title">Reflita Sobre Sua História</h2>
                <div className="s5-content">
                    <div className="s5-question-box">
                        <AnimatePresence mode="wait">
                            {activeQuestion !== null && (
                                <motion.p
                                    key={activeQuestion}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.5 }}
                                    className="s5-active-question"
                                >
                                    {questions[activeQuestion]}
                                </motion.p>
                            )}
                        </AnimatePresence>
                        {activeQuestion === null && (
                            <p className="s5-question-prompt">Selecione uma pergunta para refletir</p>
                        )}
                    </div>
                    <div className="s5-questions">
                        {questions.map((question, index) => (
                            <motion.button
                                key={index}
                                className={`s5-question-button ${activeQuestion === index ? 'active' : ''}`}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => setActiveQuestion(index)}
                            >
                                Pergunta {index + 1}
                            </motion.button>
                        ))}
                    </div>
                </div>
                <AnimatePresence>
                    {activeQuestion !== null && (
                        <motion.div
                            className="s5-insight"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                        >
                            <p>As respostas a estas perguntas são importantes e revelam a qualidade da sua autoestima e amor próprio.</p>
                        </motion.div>
                    )}
                </AnimatePresence>
                <motion.div 
                    className="s5-explanation"
                    initial={{ opacity: 0, y: 30 }}
                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                >
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.8, delay: 0.8 }}
                    >
                        Ao contrário do que muitas pessoas pensam, <span className="s5-highlight">Autoestima não se trata simplesmente de um belo reflexo no espelho</span>, ela é mais profunda e complexa do que imaginamos. Não abordando somente a autoimagem, mas, principalmente, o <span className="s5-highlight">autoconceito: que é o meio pelo qual nos enxergamos e, consequentemente, nos movemos</span>.
                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.8, delay: 1.1 }}
                    >
                        No Reencontro, você terá a oportunidade de <span className="s5-highlight">ressignificar experiências que te machucaram</span>, reconstruindo seu conceito próprio e <span className="s5-highlight">restaurando sua Autoestima</span>.
                    </motion.p>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default Section5;