import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Check } from 'lucide-react';
import '../styles/Section3.css';
import backgroundImage from '../assets/people-showing-support-respect-with-yellow-background-suicide-prevention-day.jpg';
import FeatureList from '../components/FeatureList';
import { ArrowRight } from 'lucide-react';


const Section3 = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                duration: 0.8,
                staggerChildren: 0.3 
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 10 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    const features = [
        "Para mulheres que sofrem com os efeitos da baixa autoestima como por exemplo: dependência emocional, complexo de inferioridade, sentimento de incapacidade e vários outros",
        "Que não conseguem realizar seus projetos pela ausência de autoconfiança",
        "Para mulheres que desejam se reconectar com sua essência e redescobrir seu verdadeiro valor",
        "Que desejam romper com os ciclos dolorosos e curar suas feridas",
        "Que buscam uma verdadeira experiência de autoconhecimento",
        "Para mulheres que precisam resgatar sua identidade e reescrever sua própria história",
        "Que necessitam resgatar ou reconstruir sua Autoestima e sua Autoconfiança",
    ];    

    return (
        <section className="s3-section" ref={ref}>
            <div className="s3-background-image" style={{ backgroundImage: `url(${backgroundImage})` }} />
            <motion.div 
                className="s3-container"
                variants={containerVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
            >
                <div className="s3-column s3-content-column">
                    <motion.h2 
                        className="s3-title"
                        variants={itemVariants}
                    >
                        Para quem é O Reencontro?
                    </motion.h2>
                    <FeatureList features={features} />

                    <motion.a 
                        href="#inscreva-se" 
                        className="s3-cta-button"
                        variants={itemVariants}
                        whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
                        whileTap={{ scale: 0.98, transition: { duration: 0.3 } }}
                    >
                        Quero participar do Reencontro <ArrowRight className="s3-arrow-icon" />
                    </motion.a>
                </div>
            </motion.div>
        </section>
    );
};

export default Section3;