import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../styles/Section17.css';

const Section17 = () => {
    const [formData, setFormData] = useState({
        name: '',
        whatsapp: ''
    });
    const [isReferral, setIsReferral] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const cupomParam = searchParams.get('cupom');
        setIsReferral(cupomParam && cupomParam.startsWith('imersao24'));
    }, []);

    useEffect(() => {
        console.log('isReferral:', isReferral);
    }, [isReferral]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let message = `Olá Lauana, me chamo ${formData.name} e gostaria de garantir minha vaga na Imersão O Reencontro`;
        if (isReferral) {
            message += `. Vim pela postagem da Raiana`;
        }
        const encodedMessage = encodeURIComponent(message);
        window.open(`https://wa.me/+5561984974972?text=${encodedMessage}`, '_blank');
    };

    return (
        <section className="s17-section" id="inscreva-se">
            <div className="s17-container">
                <motion.h2 
                    className="s17-title"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    EU CONHECI O CAMINHO E QUERO APRESENTÁ-LO À VOCÊ
                </motion.h2>
                <motion.p 
                    className="s17-description"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    Essa incrível experiência está somente a uma decisão de distância. Não se deixe
                    para depois, O Reencontro pode ser o divisor de águas que você busca viver na sua
                    história. Estou muito feliz em compartilharmos esse momento, te conhecer e
                    estarmos juntas nessa jornada, mal posso esperar.
                    É por você… vamos?
                </motion.p>
                <motion.div 
                    className="s17-form-container"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                >
                    <form onSubmit={handleSubmit} className="s17-contact-form">
                        <div className="s17-form-group">
                            <input 
                                type="text" 
                                id="name" 
                                name="name" 
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Seu nome"
                                required
                            />
                        </div>
                        <div className="s17-form-group">
                            <input 
                                type="tel" 
                                id="whatsapp" 
                                name="whatsapp" 
                                value={formData.whatsapp}
                                onChange={handleChange}
                                placeholder="Seu WhatsApp"
                                required
                                maxLength="15"
                            />
                        </div>
                        <motion.button 
                            type="submit" 
                            className="s17-submit-button"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Quero garantir minha transformação!
                            <i className="fas fa-arrow-right"></i>
                        </motion.button>

                        <motion.p 
                            className="s17-note"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.6 }}
                            style={{color:'white', fontWeight:'lighter'}}
                        >
                            Vagas limitadas, não perca sua chance!
                        </motion.p>
                    </form>
                </motion.div>
                <motion.p 
                    className="s17-note"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                >
                    "O resultado que a gente deseja está na decisão que a gente não toma <br/> e no passo
                    que a gente não dá."
                </motion.p>
            </div>
        </section>
    );
};

export default Section17;