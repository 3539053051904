import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Globe2, MapPin } from 'lucide-react';
import '../styles/Section16.css';

const Section16 = () => {
    const [selectedFormat, setSelectedFormat] = useState('presencial');
    
    const formatData = {
        presencial: {
            icon: MapPin,
            title: "Evento Presencial",
            details: [
                { title: "Dias", content: "14 e 15 de Dezembro de 2024" },
                { title: "Horário", content: "Das 10h às 19h" },
                { title: "Local", content: "Avenida Iguaçu 3001, Bairro Água Verde - Curitiba PR" }
            ]
        },
        online: {
            icon: Globe2,
            title: "Evento Online",
            details: [
                { 
                    title: "Duração",
                    content: <>
                        <span className="text-yellow-500 font-semibold">6 sessões</span> de imersão com
                        <span className="block"><span className="text-yellow-500 font-semibold">2h a 2h30</span> de duração cada.</span>
                    </>
                },
                { 
                    title: "Horário",
                    content: <>
                                    Horário ajustado de acordo com <span className="text-yellow-500 font-semibold">as disponibilidades.</span>

                    </>
                },
                { 
                    title: "Plataforma",
                    content: <>
                        Encontros via <span className="text-yellow-500 font-semibold">Zoom</span> e comunicação via  <span className="text-yellow-500 font-semibold">WhatsApp.</span> 
                     
                    </>
                }
            ]
        }
    };

    return (
        <section className="s16-section">
            <div className="s16-container">
                <motion.h2 
                    className="s16-main-title"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, type: "spring" }}
                >
                    Mentoria Imersiva - O Reencontro
                </motion.h2>

                <motion.h3 
                    className="s16-subtitle"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, delay: 0.3 }}
                >
                    MÉTODO: OS 6 PILARES DA AUTOESTIMA
                </motion.h3>

                <motion.div 
                    className="flex flex-col sm:flex-row justify-center items-center gap-3 sm:gap-4 mb-8 w-full px-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                >
                    {Object.entries(formatData).map(([format, data]) => (
                        <button
                            key={format}
                            onClick={() => setSelectedFormat(format)}
                            className={`flex items-center justify-center w-full sm:w-auto gap-2 px-6 py-3 rounded-full transition-all duration-300
                                ${selectedFormat === format 
                                    ? 'bg-yellow-500 text-white shadow-lg scale-105' 
                                    : 'bg-white/10 text-yellow-500 hover:bg-white/20'}
                                text-sm sm:text-base`}
                        >
                            <data.icon className="w-4 h-4 sm:w-5 sm:h-5" />
                            <span className="font-medium">{format === 'presencial' ? 'Presencial' : 'Online'}</span>
                        </button>
                    ))}
                </motion.div>
                
                <AnimatePresence mode="wait">
                    <motion.div 
                        key={selectedFormat}
                        className="s16-info-container"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                    >
                        {formatData[selectedFormat].details.map((section, index) => (
                            <div key={index} className="s16-info-item">
                                <h4 className="s16-info-title">{section.title}</h4>
                                <p className="s16-info-content leading-relaxed">{section.content}</p>
                            </div>
                        ))}
                    </motion.div>
                </AnimatePresence>

                <motion.div 
                    className="s16-presenter"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.7 }}
                >
                    <div className="s16-presenter-content">
                        <h3 className="s16-presenter-name">Lauana Quirino</h3>
                        <p className="s16-presenter-title">Psicóloga e Mentora de mulheres</p>
                        <p className="s16-presenter-specialty">Especialista em Autoconhecimento & Autoestima</p>
                    </div>
                </motion.div>

                <motion.a 
                    href="#inscreva-se" 
                    className="s16-cta-button"
                    whileHover={{ scale: 1.05, boxShadow: "0 0 25px rgba(218, 165, 32, 0.5)" }}
                    whileTap={{ scale: 0.95 }}
                >
                    Inscreva-se Agora
                </motion.a>
            </div>
        </section>
    );
};

export default Section16;