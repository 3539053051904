import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../styles/Section2.css';

const Section2 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.3 
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section className="s2-section" ref={ref}>
      <motion.div 
        className="s2-container"
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <motion.h2 
          className="s2-title s2-highlight"
          variants={itemVariants}
        >
          Porque você precisa do Reencontro?
        </motion.h2>
        
        <motion.p 
          className="s2-subtitle"
          variants={itemVariants}
        >
          Em algum momento da vida podemos nos perder pelo caminho,
          <span className="s2-highlight"> nos desconectando de nós mesmos</span>, e a razão
          disso, muitas vezes, são as feridas emocionais<br/>
          adquiridas ao longo da vida
        </motion.p>
        
        <motion.p 
          className="s2-description"
          variants={itemVariants}
        >
          Muitas dessas feridas nos fazem desenvolver
          comportamentos, aparentemente, para nos
          defender de sentimentos que não queremos
          reviver, porém, esses comportamentos
          acabam nos <span className="s2-highlight">aprisionando em ciclos dolorosos
          e repetitivos</span>
        </motion.p>
        
        <motion.p 
          className="s2-conclusion s2-highlight"
          variants={itemVariants}
        >
          ALGUMAS DAS FERIDAS QUE VOCÊ PODE ESTAR CARREGANDO
        </motion.p>
      </motion.div>
      
      <motion.div 
        className="s2-visual-section"
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <div className="s2-visual-background"></div>
        <div className="s2-visual-content">
          <div className="s2-cycle">
            {[
              'Abandono', 'Rejeição', 'Crítica', 'Humilhação', 'Traição',
              'Injustiça', 'Superproteção', 'Comparação', 'Complexo de inferioridade',
              'Ausência de valor', 'Dependência emocional'
            ].map((item, index) => (
              <motion.div 
                key={item}
                className="s2-cycle-item"
                variants={itemVariants}
              >
                {item}
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Section2;