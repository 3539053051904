import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ArrowRight } from 'lucide-react';
import '../styles/Section6.css';
import logoImage from '../assets/logo (2).png';

const Section6 = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                duration: 0.8,
                ease: "easeOut",
                staggerChildren: 0.3 
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    return (
        <section className="s6-section" ref={ref}>
            <motion.div 
                className="s6-container"
                variants={containerVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
            >
                <motion.div 
                    className="s6-logo-container"
                    variants={itemVariants}
                >
                    <div className="s6-logo-wrapper">
                        <img 
                            src={logoImage}
                            alt="Logo Imersão" 
                            className="s6-logo-image"
                        />
                    </div>
                </motion.div>
                <motion.div 
                    className="s6-content"
                    variants={itemVariants}
                >
                    <ul className="s6-description">
                        <li>É uma imersão que te levará a viver grandes descobertas pessoais te reconectando com a verdadeira essência de uma mulher única e de grande valor.</li>
                        <li>Um mergulho profundo de resgate no amor próprio e na Autoestima.</li>
                        <li>É a autorização para o surgimento de uma nova e melhor versão.</li>
                    </ul>
                    <div className="s6-quote">
                        "Nenhuma mulher continua a mesma após se reencontrar."
                    </div>
                    <motion.a 
                        href="#inscreva-se" 
                        className="s6-cta-button"
                        whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
                        whileTap={{ scale: 0.98, transition: { duration: 0.3 } }}
                    >
                        Quero participar do Reencontro <ArrowRight className="s6-arrow-icon" /> 
                    </motion.a>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default Section6;