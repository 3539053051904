import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

const FeatureList = ({ features }) => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                staggerChildren: 0.2 
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.6,
                ease: "easeOut"
            }
        }
    };

    return (
        <motion.ul 
            className="s3-feature-list"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            {features.map((feature, index) => (
                <motion.li key={index} className="s3-feature-item" variants={itemVariants}>
                    <div className="s3-feature-icon">
                        <Check size={20} />
                    </div>
                    <p className="s3-feature-description">{feature}</p>
                </motion.li>
            ))}
            <style jsx>{`
                .s3-feature-list {
                    list-style-type: none;
                    padding: 0;
                    margin-bottom: 2rem;
                }

                .s3-feature-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    padding: 0.5rem 1rem;
                    border-left: 2px solid #DAA520;
                    transition: all 0.3s ease;
                }

                .s3-feature-item:hover {
                    background: rgba(218, 165, 32, 0.1);
                    transform: translateX(5px);
                }

                .s3-feature-icon {
                    flex-shrink: 0;
                    margin-right: 1rem;
                    color: #DAA520;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .s3-feature-description {
                    font-size: 1.1rem;
                    line-height: 1.6;
                    color: #E0E0E0;
                    margin: 0;
                }

                @media (max-width: 768px) {
                    .s3-feature-item {
                        padding: 0.5rem;
                    }

                    .s3-feature-description {
                        font-size: 1rem;
                    }
                }
            `}</style>
        </motion.ul>
    );
};

export default FeatureList;