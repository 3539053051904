import React, { useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import styled, { createGlobalStyle } from 'styled-components';
import Section1 from './views/Section1';
import Section2 from './views/Section2';
import Section3 from './views/Section3';
import Section5 from './views/Section5';
import Section6 from './views/Section6';
import Section7 from './views/Section7';
import Section9 from './views/Section9';
import Section13 from './views/Section13';
import Section16 from './views/Section16';
import Section17 from './views/Section17';
import Section18 from './views/Section18';
import Section19 from './views/Section19';
import Section20 from './views/Section20';
import './styles/App.css';

import bg1 from './assets/s1.JPG';
import bg2 from './assets/s2.jpeg';
import bg5 from './assets/s5.JPG';
import bg6 from './assets/s6.jpeg';
import bg7 from './assets/s7.jpeg';
import bg9 from './assets/s9.jpeg';
import bg16 from './assets/s16.jpg';
import bg17 from './assets/s17.JPG';
import bg18 from './assets/s18.jpg';
import bg19 from './assets/s19.png';
import bg20 from './assets/s20.jpeg';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCdWfFnTcAh_sh-_53nn2AVQeqhr5i_kGE",
  authDomain: "imersaoreecontro.firebaseapp.com",
  projectId: "imersaoreecontro",
  storageBucket: "imersaoreecontro.appspot.com",
  messagingSenderId: "62613141511",
  appId: "1:62613141511:web:e480cac3b258089f1f52a2",
  measurementId: "G-YSYBZZSXZ3"
};

// Global styles
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

  body, h1, h2, h3, h4, h5, h6, p, span, a, button {
    font-family: 'Poppins', sans-serif;
  }
`;

const StyledSection = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${props => `url(${props.bg})`};
    background-size: cover;
    background-position: center;
    filter: brightness(${props => props.brightness});
    z-index: -1;
  }
`;

const StyledSection1 = styled(StyledSection)``;
const StyledSection2 = styled(StyledSection)``;
const StyledSection5 = styled(StyledSection)``;
const StyledSection6 = styled(StyledSection)``;
const StyledSection7 = styled(StyledSection)``;
const StyledSection9 = styled(StyledSection)``;
const StyledSection16 = styled(StyledSection)``;
const StyledSection17 = styled(StyledSection)``;
const StyledSection18 = styled(StyledSection)``;
const StyledSection19 = styled(StyledSection)``;
const StyledSection20 = styled(StyledSection)``;

const App = () => {
  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    // Apply smooth scroll behavior globally
    document.documentElement.style.scrollBehavior = 'smooth';

    // Add smooth scroll for internal links
    const smoothScrollToAnchor = (e) => {
      const targetId = e.target.getAttribute('href');
      if (targetId && targetId.startsWith('#')) {
        e.preventDefault();
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Add event listener to all internal links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', smoothScrollToAnchor);
    });

    // Cleanup function
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener('click', smoothScrollToAnchor);
      });
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <div className="app">
        <StyledSection1 bg={bg1} brightness="0.07"><Section1 /></StyledSection1>
        <StyledSection2 bg={bg2} brightness="0.1"><Section2 /></StyledSection2>
        <Section3 />
        <StyledSection5 bg={bg5} brightness="0.1"><Section5 /></StyledSection5>
        <StyledSection6 bg={bg6} brightness="0.1"><Section6 /></StyledSection6>
        <StyledSection9 bg={bg9} brightness="0.2"><Section9 /></StyledSection9>
        <StyledSection7 bg={bg7} brightness="0.1"><Section7 /></StyledSection7>
        <Section13 />
        <StyledSection16 bg={bg16} brightness="0.05"><Section16 /></StyledSection16>
        <StyledSection17 bg={bg17} brightness="0.1"><Section17 /></StyledSection17>
        <StyledSection18 bg={bg18} brightness="0.07"><Section18 /></StyledSection18>
        <StyledSection19 bg={bg19} brightness="0.09"><Section19 /></StyledSection19>
        <StyledSection20 bg={bg20} brightness="0.1"><Section20 /></StyledSection20>
      </div>
    </>
  );
};

export default App;