import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Section19.css';

const Section19 = () => {
    const paragraphs = [
        "Começou comigo ainda bem novinha, aos 3 anos de idade, partindo para uma cidade distante para ser criada por outra mulher a qual veio a se tornar minha mãe adotiva. Cresci muito distante da minha cidade natal e de qualquer convívio com laço sanguíneo. Escutei desde nova que minha mãe não me quis e que ninguém sabia quem era o meu pai. Fui crescendo completamente perdida, carente de afeto e de uma identidade.",
        "Com a ferida da rejeição instalada no meu inconsciente, tinha muito medo de reviver as mesmas dores registradas nas primeiras experiências de quando criança, por conta disso, fui desenvolvendo inseguranças provenientes de uma autoestima que não existia. Eu desejava mais que tudo ter uma identidade definida e um sentido de ser, mas essas coisas não se ensinam na escola, então, ainda carreguei essa ferida por muitos anos.",
        "Eu não sabia quem era e nem o valor que eu tinha. Dependente emocionalmente, machucada e cansada de sempre viver os mesmos ciclos: baixa autoestima, complexo de inferioridade, além de sofrer com a falta de validação e reconhecimento. Por consequência de uma autoestima completamente danificada, vivi relacionamentos tóxicos e abusivos que me fizeram afundar ainda mais na ausência de um sentido de vida. MAS, TUDO COMEÇOU A MUDAR QUANDO DEUS ME APRESENTOU UM NOVO CAMINHO.",
        "Foi conhecendo o amor de Deus por mim que eu descobri o que era o AMOR PRÓPRIO e a partir de então minha vida começou a se transformar. Incansavelmente eu busquei por me conhecer, o autoconhecimento se tornou um alvo de todos os dias. Eram tantas feridas para curar, tantas dores para sanar que a restauração foi incrivelmente grande. Eu me permiti e quanto mais me permitia mais me apaixonava pelo processo da ressignificação.",
        "Decidi então mergulhar nesse universo do autoconhecimento, me tornei uma psicóloga, me dediquei incansavelmente em aprender e me capacitar, obter ferramentas, cursos, especializações, e desejava ardentemente (como ainda desejo) ajudar e ensinar mulheres que assim como eu um dia sofreram ou sofrem com feridas na alma e na autoestima. Deus me entregou um chamado, eu precisei ser curada para poder curar, para poder apresentar um caminho até a autoestima que a vida infelizmente não ensina.",
        "Eu acessei o melhor de mim e o resultado é esse: Já são centenas de mulheres alcançadas e transformadas, seja através dos atendimentos em psicoterapia ou das Mentorias realizadas ao longo desses anos. Eu aprendi a me amar, a sarar minhas feridas, eu me REENCONTREI. Conheci o caminho e quero apresentá-lo a você. Deus e essa minha jornada me ensinaram que o amor-próprio é um mandamento e também uma regra para uma autoestima saudável e uma vida livre e feliz.."
    ];

    return (
        <section className="s19-section">
            <div className="s19-container">
                <motion.h2 
                    className="s19-title"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    Conheça um pouco da minha história e jornada!
                </motion.h2>
                <motion.div 
                    className="s19-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.3 }}
                >
                    {paragraphs.map((paragraph, index) => (
                        <motion.div 
                            key={index}
                            className="s19-paragraph-container"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.5 + index * 0.2, duration: 0.6 }}
                        >
                            <div className="s19-paragraph-number">{index + 1}</div>
                            <p className="s19-paragraph">{paragraph}</p>
                        </motion.div>
                    ))}
                </motion.div>
                <motion.div 
                    className="s19-quote"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 2, duration: 0.6 }}
                >
                    "Eu passei pelo processo de cura e ressignificação na minha história e hoje quero
                    ser essa ponte de luz para te ajudar a restaurar a tua."
                </motion.div>
            </div>
        </section>
    );
};

export default Section19;